.button-backup {
  display: flex;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 0px 24px;
  width: initial;
  height: 50px;
  background: #58a881;
  border-radius: 8px;
  cursor: pointer;
  margin: 0px 10px;
}

.white-space-4 {
  padding: 15px 0px;
  width: 100%;
  height: auto;
}

.p-l-30 {
  padding-left: 30px !important;
}

.center-buttons {
  min-width: 0px !important;
  text-align: center !important;
  align-items: center;
  flex-wrap: nowrap;
  display: flex;
  justify-content: center;
}
