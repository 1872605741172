.modal-detalhe-produto {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 20px 12px;
  text-align: center;
  width: 35vw;
  height: auto;
  /* width: 400px;
  height: 600px; */
}

.img-detalhe-foto {
  margin-bottom: 24px;
  width: 91.5%;
  height: auto;

  /* width: 350px;
  height: 530px; */
}
.closeIconFotos {
  text-align: right;
  margin-bottom: 10px;
}
/* .box-detalhe-foto {
  padding: 24px 48px;
} */

.titulo-modal-detalhe-foto {
  color: #272424;
  font-size: 16px;
  font-weight: 500;
  /* height: 20px; */
}
.box-setas-foto {
  display: flex;
  align-items: center;
  /* margin-top: 5px; */
  flex-wrap: wrap;
  text-align: center;
  align-content: center;
  width: 100%;
}
.colorWhite {
  color: #ffffff;
}
