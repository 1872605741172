.th {
  min-width: 0px !important;
  text-align: center !important;
  padding: 0px 10px;
}
.box-geral {
  display: flex;
  align-items: end;
  justify-content: end;
}

.resumo-diario {
  margin-bottom: 20px;
}

.titulo-acesso {
  text-align: center;
  width: 100%;
}
.btn-exportar-log {
  padding: 8px 24px;
  height: 45px;
  display: flex;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 8px;
  background-color: #dcc6b7;
  align-items: center;
  float: right;
}

.btn-exportar-scraping {
  margin: 0px 10px 10px;
}

.centralizar-tabela {
  margin-left: 8px;
}

.input-total-receita {
  width: 100%;
  /* padding: 0px 24px; */
  height: 45px;
  display: flex;
  margin: 0px 15px;
  border-radius: 4px;
  align-items: center;
}

.total-exportar {
  width: 100%;
  display: flex;
  align-items: center;
}

.tab-scraping button {
  height: 50px !important;
}

.total-scraping {
  font-size: 16px;
  font-weight: 600;
  margin: 10px 10px 0px 10px;
}
