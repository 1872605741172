.card-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* height: 300px; */
  height: auto;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 24px;
  min-width: 35vw !important;
}
.closeIcon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.body-modal-excluir {
  width: 100%;
  height: auto;
  text-align: center;
}
.img-modal-excluir {
  width: 140px;
}

.msg-confirmacao {
  font-family: "Inter", sans-serif !important;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 48px;
}

.btn-modal-confirmacao {
  width: 100%;
  padding: 16px 24px;
  border-radius: 8px;
  display: flex;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.footer-btn-modal-excluir {
  width: 100%;
  display: flex;
  align-items: center;
}
.btn-modal-cancelar {
  border: 1px solid #cecece;
  margin: 0 8px;
}
.btn-modal-excluir {
  background-color: #dcc6b7;
  margin: 0 8px;
}
