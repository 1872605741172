.div-icon-info {
  display: flex;
  align-items: flex-end;
  text-align: end;
}

.modalFormat {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 24px;
  height: 80vh;
}

.image-modal {
  margin-left: auto;
  margin-right: auto;
  height: 80%;
  width: 100%;
}

.img-responsive {
  width: 100%;
  height: 80%;
}

.modalFormat-referencias {
  min-width: 63vw;
  max-height: 90vh;
  min-height: 90vh;
  overflow: auto;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.top-box {
  margin: 10px 0px;
  justify-content: space-between;
}
.title-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.closeIcon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

h6 {
  font-family: "Inter", sans-serif;
  text-transform: lowercase;
}

h6::first-letter {
  text-transform: uppercase;
}

.title-modal-pagenew {
  font-family: "Inter", sans-serif !important;
  font-weight: 600 !important;
  font-size: 32px !important;
  text-align: center !important;
}

.text-modal-pagenew {
  font-family: "Inter", sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  text-align: center !important;
}
