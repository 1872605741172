.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: #148a6a !important;
}

.containerGamefication {
  padding: 16px 24px !important;
}

.gameficationGeralContainer{
  margin: 32px 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.gameficationOutsideBox {
  background: #fff;
  width: 28%;
  height: auto;
  min-height: 700px;
  border-radius: 8px;
  padding: 40px 24px;
}

.gameficationTitle {
  color: var(--color-typography-t-2-pure, #272424);
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 38px;
  min-height: 60px;
}

.podiumSecondAndThird{
  display: flex;
  /* background: blue; */
  width: 100%;
  height: auto;
  justify-content: space-between;
  align-items: center;
  margin-top: -65px;
  margin-bottom: 48px;
}

/* Estilos para dispositivos móveis */
@media screen and (max-width: 767px) {
  .containerGamefication {
    padding: 16px 0px !important;
  }

  .gameficationGeralContainer{
    display: block;
    width: 100%;
    margin: 0 auto;
  }

  .gameficationOutsideBox{
    width: 100%;
    padding: 40px 0px;
    margin: 32px 0px;
  }

  .podiumSecondAndThird{
    display: flex;
    width: 90%;
    margin: 0 auto;
    margin-top: -65px;
    margin-bottom: 48px;
  }
}