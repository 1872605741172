.modal-fotos {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 85vh;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 40px;
  width: 70vw !important;
  max-width: 70vw;
  overflow: scroll;
}

.modal-nova-pasta {
  width: 392px !important;
  height: 260px !important;
}

.closeIconFotos {
  float: right;
}

.title-modal-fotos {
  color: #272424;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.box-upload-fotos {
  margin-top: 24px;
  margin-bottom: 30px;
  height: 120px;
}

input[type="file"] {
  display: none;
  /* z-index: -1; */
  height: inherit;
  width: 100%;
}

.label-input-file {
  background-color: rgba(249, 249, 249);
  border-radius: 8px;
  border: 1px dashed #3f3d56;
  cursor: pointer;
  padding: 8px 16px;
  height: inherit;
  display: flex;
}
.box-upload-fotos-2 {
  width: 100%;
  display: grid;
  text-align: center;
  align-items: center;
}
.texto-upload-fotos {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  align-items: center;
  text-align: center;
}
.texto-upload-fotos-2 {
  color: #8b8b8b;
  margin-top: 8px;
}

.icone-upload-fotos {
  font-size: 50px !important;
  color: #757575 !important;
  margin-bottom: 16px;
}

.btn-add-foto-modal {
  border: 0 !important;
  height: 48px !important;
  background-color: #ebebeb !important;
  cursor: pointer;
  color: #616161 !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  width: 200px;
}
.box-btn-add-foto-modal {
  display: flex;
  float: right;
}
.box-btn-add-foto-modal-2 {
  display: flex;
  float: right;
}

.btn-add-foto {
  height: 48px !important;
  background-color: #dcc6b7 !important;
  cursor: pointer;
  color: #272424 !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  width: 200px;
}

.box-progresso {
  display: flex;
  align-items: center;
}
.card-back-red {
  background-color: #fff3f2 !important;
}
.box-nome-incorreto {
  color: #f94f44;
  font-size: 14px;
  padding: 8px 24px 8px 16px;
  background-color: #fff3f2;
  border-radius: 8px;
  height: 48px;
  margin: 16px 0;
  display: flex;
  align-items: center;
  align-content: center;
}
.remover-fotos-nome-incorreto {
  text-align: right;
  width: 10%;
  cursor: pointer;
}

.box-input-nova-pasta {
  display: flex;
  align-items: center;
  min-height: 150px;
}
.imput-nova-pasta {
  width: 100%;
  margin: 10px 0;
  border-radius: 8px;
  border: #bdbdbd 1px solid;
  height: 30px;
  padding: 16px 24px;
}
#btn_nova_pasta {
  margin: 0px !important;
}

.m-box-15 {
  margin: 0px 8px !important;
}

@media screen and (max-width: 960px) {
  .footer-btn-modal-pasta-foto {
    display: block;
  }

  .btn-pasta-foto {
    width: initial;
    justify-content: initial;
    border: 1px solid #cecece;
    margin: 15px;
  }

  .m-box-15 {
    margin: initial !important;
  }

  .box-btn-add-foto-modal {
    float: unset;
    position: fixed !important;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    display: flex; /* inicia o display flex */
    align-items: center; /* alinha os itens na vertical */
    justify-content: center; /* alinha os itens na horizontal */
    width: 100vw;
    padding: 24px 0;
    z-index: 99;
  }
  .btn-add-foto {
    width: 90vw;
  }
}
