.containerlogin {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.container-rec {
  display: flex;
  flex-direction: row;
  height: 100vh; 
  overflow: hidden;
}

.aformat {
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: block;
  align-items: center;
  text-align: center;
  color: #272424;
  margin: 36px;
}

.voltarformat {
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: block;
  align-items: center;
  text-align: center;
  color: #272424;
  margin: 36px;
}

.h1format {
  font-family: "Inter", sans-serif !important;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  text-transform: uppercase;
  color: #272424;
  margin-top: 18vh;
  margin-bottom: 6vh;
}

.h1format-rec {
  font-family: "Inter", sans-serif !important;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  text-transform: uppercase;
  color: #272424;
  margin-top: 18vh;
  margin-bottom: 6vh;
}

.loginPage {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  background: #ffffff;
  flex-direction: column;
  padding: 25px;
  width: 100%;
  overflow: hidden;
}

.logo {
  width: 124px;
  height: 16px;
  bottom: 40px;
  position: fixed;
}

.sectionPattern {
  width: 100%;
  align-items: center;
  align-content: center;
  display: block;
  text-align: center;
}

.solicSuccess {
  width: 191px;
  height: 198px;
  background: url(./../../static/undraw_Completing_re_i7ap1.svg);
  margin-top: 14vh;
  margin-bottom: 3vh;
}
.h2format {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 34px;
  text-align: center;
}

.h2format-rec {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 34px;
  text-align: center;
  padding-bottom: 8px;
}

.h2format-suc {
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  
}

.h3format {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #616161;
  margin-top: 10px;
  margin-bottom: 32px;
}

.h3format-rec {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #616161;
  margin-bottom: 32px;
}

.textError {
  text-align: left;
  padding-top: 5px;
  color: #c52518;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px;
  line-height: 105%;
}



.button-active {
  color:#272424 ;
  background: #DCC6B7 ;
}

.iconeVoltar {
  display: block !important;
  position: absolute;
  left: 8%;
  top: 8%;
  color: #424242;
  cursor: pointer;
}

.loginPage {
  width: 100%;
  height: 100vh;
  padding-top: 2%;
}

.pageImage {
  width: inherit;
  height: inherit;
  display: block;
}

.loginImage {
  width: inherit;
  height: 100vh;
}

@media (max-width: 959px) {
  .pageImage {
    display: none;
  }

  .voltarformat {
   display: none;
  }
}

@media (min-width: 1200px) {
  .iconeVoltar {
    display: none !important;
  }

  .sectionPattern {
    width: 50%;
  }

  @media (min-width: 1495px) {
   
  }

  @media (min-width: 1850px) {
    
  }

  @media (min-width: 2450px) {
    
  }
}
