.modal-datas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 85vh;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 40px;
  width: 70vw !important;
  max-width: 70vw;
  overflow: scroll;
}
.closeIconFotos {
  float: right;
}

.title-modal-datas {
  color: #272424;
  font-size: 20px;
  font-weight: 500;
}
.box-upload-datas {
  margin-top: 24px;
  margin-bottom: 30px;
  height: 120px;
}

input[type="file"] {
  display: none;
  /* z-index: -1; */
  height: inherit;
  width: 100%;
}

.label-input-file {
  background-color: rgba(249, 249, 249);
  border-radius: 8px;
  border: 1px dashed #3f3d56;
  cursor: pointer;
  padding: 8px 16px;
  height: inherit;
  display: flex;
}
.box-upload-datas-2 {
  width: 100%;
  display: grid;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.texto-upload-data {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  align-items: center;
  padding: 10px;
}
.texto-upload-datas-2 {
  color: #8b8b8b;
  margin-top: 8px;
}

.icone-upload-datas {
  font-size: 50px !important;
  color: #757575 !important;
  margin-bottom: 16px;
}

.btn-add-foto-modal {
  border: 0 !important;
  height: 48px !important;
  background-color: #ebebeb !important;
  cursor: pointer;
  color: #616161 !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  width: 200px;
}
.box-btn-modal {
  padding-right: 10px;
  padding-left: 20px;
}

.btn-add-foto {
  height: 48px !important;
  background-color: #dcc6b7 !important;
  cursor: pointer;
  color: #272424 !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  width: 200px;
}
.div-btn-exportar-importar {
  display: flex;
  align-items: center;
  /* padding: 10px; */
}

@media screen and (max-width: 960px) {
  .box-btn-add-foto-modal {
    float: unset;
    position: fixed !important;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    display: flex; /* inicia o display flex */
    align-items: center; /* alinha os itens na vertical */
    justify-content: center; /* alinha os itens na horizontal */
    width: 100vw;
    padding: 24px 0;
    z-index: 99;
  }
  .btn-add-foto {
    width: 90vw;
  }
}
