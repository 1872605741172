
.titulo-receita {
  font-weight: 600 !important;
  font-size: 24px !important;
  word-break: normal;
}

.titulo-box-ofertados {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #272424;
}

.divider-ofertados {
  margin-bottom: 30px !important;
  margin-top: 20px !important;
  border-bottom: #cecece solid 0.5px;
  border-left: none;
  width: 100%;
  height: auto;
}

.box-skus {
  display: flex;
  width: 100%;
  align-items: center;
}

.sub-titulo-1-ofertados{
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #616161 !important;
}

.sub-titulo-2-ofertados {
  margin-left: 5px !important;
}

.box-principal {
  display: initial;
  align-items: initial;
}

.box-individual-ofertados {
  flex-wrap: nowrap !important;
  margin-top: 20px;
  padding: 10px 15px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 4px;
}

.h5-ofer {
  min-width: 2vw !important;
}

.skeleton-ofertados {
  min-height: 50px !important;
  padding: 0px 10px;
}

@media only screen and (min-width: 970px) {
  .divider-ofertados {
    border-left: #cecece solid 0.5px;
    height: 50px;
    border-bottom: none;
  }
  .divider-ofertados {
    width: inherit;
    margin-right: 20px;
    margin-left: 20px;
  }
  .box-principal {
    display: flex;
    align-items: center;
  }

.sub-titulo-1-ofertados{
  font-size: 18px !important;
}
.sub-titulo-2-ofertados {
  font-size: 24px !important;
}

}
