.inputFarol[type="text"] {
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  background-color: #e5e5e5;
  padding: 12px;
  display: flex;
}

.box-busca-topo {
  justify-content: flex-end;
  display: flex;
  width: 100%;
}

.input-farol-aberto {
  background-color: #fff !important;
  padding-left: 35px !important;
  padding-right: 30px !important;
}

.searchIcon {
  z-index: 1;
  margin: 10px 5px;
  position: absolute;
  color: #616161;
  cursor: pointer;

  font-size: 23px;
}

#closeBusca {
  float: left;
  margin: 10px 0px 0px -27px;
}

@media screen and (max-width: 959px) {
  .esconder-info-top-2 {
    display: none;
  }

  .input-farol-aberto {
    /* width: 88vw !important; */
    padding-left: 9vw !important;
  }
}

/* @media screen and (max-width: 590px) {
  .input-farol-aberto {
    width: 86vw !important;
  }
} */

@media screen and (min-width: 959px) {
  .box-busca-topo {
    min-width: 250px;
    margin-right: 20px;
  }
}
