.scroll-menu {
  overflow: auto;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  position: sticky;
  width: 100%;
}

.MuiOutlinedInput-root {
  border-radius: 6px !important;
}

.MuiFormControl-root {
  border-radius: 6px !important;
}

.scroll-fixo {
  top: 26%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  padding: 24px;
  width: 93vw;
  position: fixed;
  z-index: 999999;
  display: flex;
}

.btn-sel-colecoes-select {
  border: 0;
  padding: 8px 16px;
  height: 40px;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
  border-radius: 8px;
  background-color: #dcc6b7;
  width: 100%;
}

.campo-skeleton{
  padding: 0px 8px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
}

@media screen and (max-width: 900px) {
  .scroll-fixo {
    top: 50px;
  }
}
