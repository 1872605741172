.rectangle {
    height: 65px;
    width: 149px;
    background-color: #fff;
}

.farol{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700 !important;
    font-size: 17px !important;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    padding-top: 2%;

}

.farol-close{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700 !important;
    font-size: 17px !important;
    display: flex;
    flex-direction: row !important;
    gap: 20% !important;
    align-items: center;
    justify-content: center;
    margin-right: 90% !important;
    margin-left: -30% !important;
    padding-top: 2%;
    color: #000; 
    }

.header{
    padding: 5px;
}
.insert-box-chart{
    height: 36px !important;
    width: 30px !important;
    background-color: #DCC6B7;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 8px;
}

.insert-box{
    background-color: #DCC6B7;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
   
}

.camera-box-chart{
    height: 36px !important;
    width: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 8px;
    /* color: #757575 !important; */
}

.css-2lkgwd-MuiSvgIcon-root {
    color: #272424;
}

.gest-drop-chip{
    display: flex !important;
}

.gest-drop{
    padding-right: 20px !important;
}

.chip-expand {
    margin-left: 10px !important;
}



.closed-icon {
    display: flex !important;
    justify-content: flex-end;
    padding: 14px !important;
}

.user-info {
    padding: 20px !important;
}



.camera-box-mobile{
    height: 36px !important;
    width: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 8px;
    color: #212424 !important;
    margin-left:-5px;
    margin-right: 6px;
}

.camera-box-chart2{
    height: 36px !important;
    width: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 8px;
}

.farol-header{
    padding-left: 10% !important;
}

.arrow-back{
    padding-left: 20% !important;
}

.footerDrawer{
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logout {
    font-family: sans-serif !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}


@media screen and (max-width: 959px) {
    .menu-desk {
      display: none !important;
    }
    .menu-mobile {
        display: block !important   ;
    }
  }