.css-1t29gy6-MuiToolbar-root {
  padding-right: 0 !important;
}

.css-17njz95{
  padding: 0px !important;
  padding-top: 3vh !important;
  padding-bottom: 4vh !important;
  padding-right: 3vh !important;
  
}
.icones-menu-aberto {
  display: none !important;
}
.icones-menu-fechado {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: relative !important;
}

@media screen and (min-width: 959px) {
  .menu-mobile {
    display: none !important;
  }
  .menu-desk {
    display: block !important;
  }
}