body {
  font-family: "Lato", sans-serif;
  transition: background-color 0.5s;
}

.hover-menu-aberto:hover {
  background-color: #dcc6b7 !important;
}

.icones-menu-aberto {
  display: none !important;
}
.icones-menu-fechado {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: relative !important;
}

.img-marca {
  padding: 40px 20px;
  text-align: center;
}

.botao {
  font-size: 30px !important;
  cursor: pointer !important;
  position: fixed !important;
  z-index: 99;
  height: 25px;
  width: 25px;
  color: #fff !important;
  background-color: #866c6d !important;
  margin-top: 20px !important;
}

.sidenav {
  height: 100%;
  width: 100px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.5s;
  padding: 30px, 0px, 32px, 0px;
  box-shadow: 1px 0px 0px #dedede;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  color: red;
}

#main {
  margin-left: 85px;
  transition: margin-left 0.5s;
  padding: 16px;
}

/* #iconeGestao,
#iconeEstudio,
#iconeGamefication {
  height: 36px !important;
  width: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 8px;
} */
.icones {
  height: 36px !important;
  width: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 8px;
}
.colorirMenu {
  background-color: #dcc6b7;
}

@media screen and (max-height: 450px) {
  .sidenav a {
    font-size: 18px;
  }
}
