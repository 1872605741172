.input-mui{
    position: relative;
    margin-top: 24px;
}

.div-visibility {
   position: absolute;
   border: none;
   background-color: #fff;
   right: 3%;
   top: 28%;
  }

.MuiFormControl-root {
    width: 100% ;
}

.MuiInputLabel-outlined {
    z-index: 3 !important;
    padding-left: 5px !important;
    padding-right: 7px !important;
    background-color: #fff !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    
}

.MuiFormLabel-root.Mui-error {
    color: #C52518 !important;
    
}

.MuiOutlinedInput-input {
    border: #cecece 1px solid !important;
    border-radius: 6px !important;
    padding: 18px 14px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #C52518;
}

.MuiOutlinedInput-input.Mui-error {
    color: #C52518 !important;
}

.errorClass  {
    border: #C52518 1px solid !important;
    color: #C52518 !important;
    border-radius: 10px !important;
   }
   


.visibility {
     color: #8B8B8B;
 }