.modal-generico {
  width: 30vw;
  height: 30vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 24px;
}

.icone-fechar {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
}

.titulo-modal-generico {
  padding-bottom: 20px;
  text-align: center;
  font-size: 24px;
}
.texto-modal-generico {
  padding-bottom: 20px 10px; 
  text-align: center;
  font-size: 16px;
}


@media screen and (max-width: 959px) {
  .modal-generico {
    min-width: 30vw;
    width: auto;
    height: auto;
  }

  .icone-fechar {
    padding-bottom: 20px;
  }

  .titulo-modal-generico {
      font-weight: 700;
      padding-bottom: 10px;
      text-align: center;
      font-size: 16px;
  }
}
