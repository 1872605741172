* {
  margin: 0;
  padding: 0;
  outline: 0;
  font-family: "Inter" !important;
}

body {
  background: #e5e5e5;
}

input:-webkit-autofill {
  background-clip: text;
  -webkit-background-clip: text;
}
.titile-first-letter::first-letter {
  text-transform: uppercase;
}
.f-w-600 {
  font-weight: 600 !important;
}
.lowerCase {
  text-transform: lowercase !important;
}
.p-16 {
  padding: 16px;
}
.scroll {
  overflow: auto;
}
.m-0 {
  margin: 0 !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.m-l-10 {
  margin-left: 10px;
}
.m-l-20 {
  margin-left: 20px !important;
}
.m-r-10 {
  margin-right: 10px;
}
.m-r-15 {
  margin-right: 15px;
}
.m-r-20 {
  margin-right: 20px !important;
}
.m-l-0 {
  margin-left: 0px !important;
}
.m-l-15 {
  margin-left: 15px;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.m-t-16 {
  margin-top: 16px !important;
}
.m-b-24 {
  margin-bottom: 24px;
}
.m-b-40 {
  margin-bottom: 40px !important;
}
.green {
  background-color: #148a6a;
}
.red {
  background-color: #c52518;
}
.orange {
  background-color: #f2a611;
}
.gray {
  background-color: #757575;
}
.purple {
  background-color: #7666ca;
}
.btn-ativo {
  background-color: #dcc6b7 !important;
}
.text-gray {
  color: #8b8b8b;
}
.text-black {
  color: #000 !important;
}
.text-red {
  color: #fe6f66 !important;
}
.displayBlock {
  display: block !important;
}
.displayNone {
  display: none !important;
}
.displayFlex {
  display: flex !important;
}

.display-flex {
  display: flex !important;
  align-items: center;
}
.w-100 {
  width: 100% !important;
}
.MuiPickersDay-daySelected {
  background-color: #dbc7b8 !important;
}

.sub-titulo-1 {
  font-family: "Inter", sans-serif !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #616161 !important;
  /* font-family: Inter, sans-serif !important; */
}
.sub-titulo-2 {
  font-family: "Inter" !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  display: flex;
  color: #272424;
  word-break: break-word;
}

.sub-titulo-3 {
  font-family: "Inter" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  align-items: center;
  color: #272424;
}

.divider {
  margin: 10px 0px;
  border-bottom: #cecece solid 0.5px;
  width: 100%;
}

.divider-top {
  margin: 10px 0px;
  border-bottom: #cecece solid 0.5px;
  width: 88vw;
}

.divider-2 {
  margin: 30px 10px;
  border-bottom: #cecece solid 0.5px;
  width: 100%;
}

.white-space-1 {
  width: 100%;
  height: auto;
}

.white-space-2 {
  padding: 10px 0px;
  width: 100%;
  height: auto;
}

.box {
  background-color: #fff;
  padding: 16px 10px;
  width: 100%;
  border-radius: 8px;
  padding-right: 0px;
}

.pageBackground {
  position: absolute;
  min-height: calc(100vh - 90px);
  width: 100%;
  background-color: #212121;
  padding: 0;
}

.pageGrid {
  width: 100%;
  min-height: calc(50vh - 103px);
  height: auto;
  background-color: #212121;
  padding: 0;
  margin-top: 4%;
}

.btn-exportar-csv {
  margin-top: 10px !important;
  text-align: right;
}

.width-100 {
  width: 100% !important;
}

.text-center {
  text-align: center !important;
}

.not-print {
  display: none !important;
}

.iconsTableHead {
  padding-left: 20px;
  cursor: pointer;
  vertical-align: middle;
  width: 2em !important;
}

.cursor {
  cursor: pointer;
}

.cursor-diretorio {
  cursor: default;
}

.chipUpload {
  min-width: 30% !important;
  height: 25px !important;
  color: #ffffff !important;
}

.chip-upload {
  height: 20px;
  min-width: 70px;
  border-radius: 25px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.chipSuccess {
  background-color: #1bb55c !important;
}

.chipPrice {
  background-color: #fff !important;
}

.chipError {
  background-color: #d66157 !important;
}
.chipWarning {
  background-color: #e3cc6c !important;
}

.border-right-cell {
  border-right: 3px solid #c4c4c4;
}

.borderRightNone {
  border-right: hidden !important;
}

.menuTitle {
  padding: 5px;
  background-color: rgb(219 199 184 / 55%);
  border: 2px solid rgb(219 199 184);
  border-left-width: 10px;
  border-left-color: rgb(219 199 184);
}

.chipTotal {
  margin-bottom: 20px;
}

.textField_label {
  color: #212121 !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.p-l-40 {
  padding-left: 40px !important;
}
.p-l-0 {
  padding-left: 0px !important;
}
.p-t-0 {
  padding-top: 0px !important;
}
.p-b-16 {
  padding-bottom: 16px !important;
}

.MuiSelect-select {
  min-width: 120px !important;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: #f4eeea !important;
}

.MuiSelect-select:focus {
  background-color: #fff !important;
  border: #cecece solid 0.5px !important;
}

.iconeAvancar {
  margin-left: auto;
  order: 2;
  color: #424242;
  cursor: pointer;
}

.format-select {
  width: 130px;
}
.input-label {
  padding-left: 10px !important;
}

.form-control {
  margin: 15px !important;
  margin-left: 0px !important;
  width: 130px;
  background-color: #fff;
}

.box-individual {
  flex-wrap: nowrap !important;
  padding: 10px 15px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.box-individual-not {
  margin-top: 5px;
}

.nao-ofertados {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5vw;
  width: 100%;
}

.nao-ofertados-cat {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5vw;
  width: 100%;
}

.nao-ofertados-text {
  display: flex;
}

.title-box-select {
  margin: 0px 0px 5px 6px !important;
}

.title-box-select-1 {
  margin-left: unset !important;
}

.select {
  align-content: center;
  min-width: 200px !important;
  background-color: #fff;
  border-radius: 4px;
  border: #cecece !important;
  margin: 0px 5px !important;
  width: auto !important;
  max-width: 200px !important;
}

.select-1 {
  margin-left: unset !important;
}

.skeleton-format {
  background-color: #6c6c6c !important;
  box-sizing: content-box;
  width: inherit !important;
  margin: 10px 0px !important;
  /* min-height: 50px !important; */
}

.box-top {
  max-width: 100%;
}

.box-select-ordenacao {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  float: right;
}

.info-top {
  display: flex;
  width: 100%;
}

.info-top-2 {
  width: 100%;
  align-items: center;
  /* display: flex; */
}

.info-top-2-gamefication {
  width: 100%;
}

.info-top-3 {
  display: inherit;
  align-items: center;
  align-content: center;
}

.info-top-3-mob {
  width: 100%;
  padding-top: 1%;
  justify-content: flex-end;
  display: flex;
}

.info-top-4 {
  /* margin-left: 10px !important; */
  margin: 0px 10px !important;
}

.container-divider {
  display: flex;
  gap: 0vw;
}

.css-1mckyxr {
  min-height: 9vw !important;
}

.search-user {
  display: flex;
  gap: 3%;
}

.hcdgjf {
  margin-top: 5vw;
}

.css-nnnwpn-MuiList-root {
  padding-top: 2vw;
}

/* ==================== COMEÇO MEDIA================= */

@media screen and (min-width: 330px) and (max-width: 430px) {
  .select {
    min-width: 46vw !important;
  }
}

@media screen and (min-width: 430px) and (max-width: 660px) {
  .select {
    width: 140px !important;
  }
}

@media screen and (min-width: 660px) and (max-width: 700px) {
  .font-selected {
    font-size: 12px !important;
  }
  .title-box {
    padding: 5px 15px !important;
  }

  .select {
    width: 150px !important;
  }
  .iconeAvancar {
    margin-right: 15px;
  }
}
@media screen and (min-width: 3000px) {
  .div-sku {
    margin-left: 50px !important;
  }
  .graph-pecas {
    margin: 0 !important;
    margin-top: 50px !important;
  }
}

@media screen and (max-width: 959px) {
  .container-divider {
    gap: 3vw;
  }

  .divider-top {
    display: none;
  }

  .info-top-2 {
    width: inherit;
  }

  .box {
    margin: 4vw 0vw;
    width: 100%;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    padding: 0px 0px;
  }

  .box-estudio {
    margin: 1vw 0vw;
  }

  .box-individual {
    box-shadow: none;
  }

  .info-top-4 {
    display: none;
  }

  .select {
    min-width: 30vw !important;
  }

  .select-scroll-lateral {
    min-width: 250px !important;
  }

  .white-space-1 {
    width: 100%;
    height: auto;
  }

  .white-space-2 {
    padding: 1vw 0px;
    width: 100%;
    height: auto;
  }

  .sub-titulo-3 {
    font-family: "Inter" !important;
    font-weight: 600 !important;
    font-size: 4vw !important;
    line-height: 1vw !important;
    align-items: center;
    color: #272424;
  }

  .divider-2 {
    margin: 3vw 1vw;
    border-bottom: #cecece solid 0.5px;
    width: 100%;
  }

  .card-divider {
    display: flex;
    flex-direction: row;
  }

  .divider-3 {
    border-left: #cecece solid 0.5px;
    height: 100%;
    margin-top: 10px;
    /* margin: 3vh; */
  }
  .nao-ofertados {
    margin: 3vw 0.5vw;
    justify-content: space-between;
  }

  .nao-ofertados-cat {
    margin: 3vw 0.5vw;
  }

  .MuiGrid-grid-xs-12 {
    flex-grow: 0;
    padding: 10px 0px 10px 5px;
    /* padding-top: 10px;
    padding-right: 5px;
    padding-left: 10px;
    padding-bottom: 2px; */
  }

  /* .css-ghv7kk-MuiContainer-root {
    width: 95% !important;
  } */

  .box-top-mob {
    max-width: 100%;
  }
  .title-box-select {
    font-size: 2.8vw !important;
  }

  .iconeAvancar {
    margin-left: 0;
    order: 0;
    color: #424242;
    cursor: pointer;
  }

  .info-top-2-not {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .info-top {
    display: flex;
    width: 100%;
    align-items: center;
  }
}

@media screen and (min-width: 959px) {
  .divider-3 {
    border-left: #cecece solid 0.5px;
    height: 50px;
    /* margin-top: 10px; */
  }
  .white-space-1 {
    width: initial;
  }
  .white-space-2 {
    width: initial;
  }
  .box {
    background-color: inherit;
    min-width: auto;
  }

  .box-individual {
    background-color: #fff;
    padding: 10px 15px;
    border-radius: 8px;
    margin-left: -10px !important;
    /* max-height: 310px !important; */
    min-height: 320px !important;
  }

  .box-individual-2 {
    min-height: 150px !important;
    background-color: #fff;
    padding: 10px 15px;
    margin: 5px 0px !important;
    border-radius: 8px;
    margin-left: 0px !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    cursor: pointer;
  }

  .box-individual-2-principal {
    margin-right: 10px !important;
  }

  .divider-2 {
    margin: inherit;
    border-bottom: inherit;
    width: inherit;
  }
  .white-space-2 {
    padding: 5px;
  }

  .box-top-mob {
    display: none;
  }

  .list-item {
    background-color: #f4eeea !important;
  }

  .list-item-close {
    background-color: #fff !important ;
  }
}

@media screen and (min-width: 1270px) and (max-width: 1400px) {
  .box-individual-2 {
    min-height: 132px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1550px) {
  .box-individual-2 {
    min-height: 138px;
  }
}

@media screen and (min-width: 1800px) and (max-width: 2150px) {
  .box-individual {
    min-height: 325px !important;
    /* min-height: 350px !important; */
  }
}

@media screen and (min-width: 1550px) {
  .box-individual-2 {
    min-height: 1140px;
  }
}

@media screen and (min-width: 1600px) {
  .box-individual-2 {
    min-height: 170px;
  }
  .box-individual {
    min-height: 350px;
  }
  .select {
    min-width: 20vw !important;
  }
}

@media screen and (min-width: 1800px) {
  .box-individual-2 {
    min-height: 256px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }
}

@media screen and (min-width: 2150px) {
  .box-individual {
    min-height: 450px !important;
  }
}

@media print {
  header {
    display: none !important;
  }
  @page {
    size: auto;
    margin: 0 10px;
  }
  .not-print {
    display: block !important;
  }
  button {
    display: none !important;
  }
}
