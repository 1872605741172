.label-checkbox input[type="checkbox"] {
  display: none;
}
.label-checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.checkbox {
  background-color: #fff; /* Used if the image is unavailable */
  border-radius: 4px;
  height: 15px;
  width: 15px;
  border: solid 1px #000;
}
.icone-check {
  height: 17px !important;
  width: 17px !important;
  background-color: #dcc6b7; /* Used if the image is unavailable */
  border-radius: 4px;
}

