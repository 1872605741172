.containerCardOffer {
  background-color: #fff;
  width: 100%;
  height: auto;
  border-radius: 8px;
  /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); */
}

.pageCardOffer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageCardOffer {
  width: 232px;
  height: 232px;
  background: url(../../../static/undraw_Happy_news_re_tsbd.svg);
  margin-top: 2vh;
}

.texto-card-offer {
  font-style: "Inter", sans-serif !important;
  font-weight: 600;
  font-size: 24px;
  font-weight: 600;
  line-height: 23px;
  text-align: center;
  padding-bottom: 8px;
}

@media screen and (max-width: 959px) {
  .texto-card-offer {
    font-size: 16px;
  }
}
