.info-icon {
    display: flex;
    width: 1.5vw;
    height: 1.5vw;
  }

.arrow-foward {
  display: flex;
    width: 1.5vw;
    height: 1.5vw;
}

.calendar-icon {
  display: flex;
  width: 1.5vw;
  height: 1.5vw;
}


  @media screen and (max-width: 959px) {
    .info-icon {
      display: flex;
      width: 5vw;
      height: 5vw;
    }

    .arrow-foward {
      display: flex;
      width: 5vw;
      height: 5vw;
    }

    .calendar-icon {
      display: flex;
      width: 5vw;
      height: 5vw;
    }
  }
    
  