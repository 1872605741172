.nome-status {
  text-transform: capitalize;
  letter-spacing: 0.1px;
  color: #272424;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
}

.dropdown {
  position: relative;
  font-family: Inter, "sans-serif";
}

.dropdown-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  /*  background-color: #dcc6b7; */
  cursor: pointer;
}
.item-dropdown-content {
  padding: 7px 0;
}
.dropdown-content {
  position: absolute;
  /* width: 220px; */
  height: fit-content;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  max-height: 300px;
  overflow: auto;
  border: 1px solid #e1e1e1;
}

.dropdown-content a {
  text-decoration: none;
  padding: 8px 16px;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.dropdown a:hover {
  background-color: #f4eeea;
  border-radius: 4px;
}
.icone-filtro {
  margin-left: 10px;
}
.icone-x-qtd {
  margin: 5px;
  font-weight: 400 !important;
}
.label-icones {
  display: flex;
  align-items: center;
  cursor: pointer;
}

@media screen and (max-width: 660px) {
  .dropdown-content {
    width: 180px;
  }
}
