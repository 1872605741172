.tamanho-card {
  width: 100%;
}

.root-card2 {
  background: #fff;
  /* flex-direction: column;
  justify-content: center;
  align-items: flex-start; */
  padding: 24px 24px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px !important;
  margin-left: 0px;
  min-height: 145px;
}
.card-total-deflatores{
  min-height: initial !important;
}

.title-card-2 {
  font-weight: 500;
  font-size: 14px;
  color: #616161;
  font-family: "Inter", sans-serif !important;
}

.box-ofertados {
  background-color: #fff;
  padding: 24px;
  width: 100%;
  margin: 20px 0px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.value-1 {
  font-weight: 600;
  font-size: 16px;
  color: #272424;
  font-family: "Inter", sans-serif !important;
}
  

.white-space-3 {
  padding: 5px 0px;
  width: 100%;
  height: auto;
}

.box-skus-1 {
  display: block;
  margin: 10px 0px;
}

.box-skus-2 {
  display: flex;
  margin: 10px 0px;
}
.box-skus-3 {
  min-height: 20px;
  margin-top: 30px;
}

.title-card-search {
  font-weight: 600;
  font-size: 14px;
  font-family: Inter, sans-serif !important;
  display: flex;
  align-items: center;
}

.color-title-2 {
  color: #616161;
}

.color-value-2 {
  color: #272424;
  margin-left: 3px;
}

.margin-value-2 {
  margin-left: 3px;
  margin-right: 20px;
}

.subt-card-2 {
  font-weight: 600;
  font-size: 16px;
  color: #616161;
  font-family: "Inter", sans-serif !important;
}

.box-secundario {
  padding-top: 8px !important;
}

.topo-card-generico {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.box-card-busca {
  padding: 24px;
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
}

.divider-card-search {
  border-left: #cecece solid 0.5px;
  height: auto;
  margin: 0px 30px;
}

.divider-card-search-2 {
  margin: 0px 15px;
}

.card-section-2 {
  padding: 15px 0;
}

.title-section-2 {
  font-size: 20px;
  color: #272424;
  font-weight: 500;
  margin-bottom: 40px;
}

.badged {
  min-height: 15px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-left: 3px;
  color: #fff;
  padding: 10px;
  font-size: 12px;
}


.default {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.custom-checkbox .checkmark:before {
  width: 12px;
  height: 12px;
  line-height: 12px;
}

/* Hide the default checkbox */
.custom-checkbox input[type="checkbox"] {
  display: none;
}

/* Style the label as desired */
.custom-checkbox {
  cursor: pointer;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

/* Style the checkmark using a pseudo-element */
.custom-checkbox .checkmark:before {
  /* content: ""; */
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #ccc6c6;
  background-color: #ffff;
  border-radius: 2px;
  margin-right: 8px;
  vertical-align: middle;
  text-align: center;
  color: #000;
  font-weight: bold;
  line-height: 16px;
}

.input-check-visivel {
  width: 24px;
  height: 24px;
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark:before {
  background-color: #DCC6B7 !important;
}



@media screen and (min-width: 600px) and (max-width: 900px) {
  .root {
    min-height: 300px;
  }
}
