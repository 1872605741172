.linha {
  text-align: center;
  justify-content: center;
  justify-items: center;
  display: flex;
}

.tabela-responsiva-marketplace {
  overflow-x: scroll;
  overflow-y: scroll;
  min-height: 250px;
  height: 80vh;
  width: 100%;
}

/* .check-coluna {
  justify-content: center;
  justify-items: center;
  display: flex;
} */

.sort {
  border: none;
  background-color: #dcc6b7;
  cursor: pointer;
}

/* ::-webkit-scrollbar-track {
  background-color: #fff;
} */

::-webkit-scrollbar {
  width: 0px;
  /*   background: #ddc7b7; */
  height: 8px;
}
::-webkit-scrollbar-thumb {
  /*  background: #ddc7b7; */
  border-radius: 5px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

tr {
  height: 50px;
}

thead {
  background-color: #dcc6b7;
}

th {
  min-width: 0px !important;
  text-align: left !important;
  padding: 0px 10px;
}

td {
  border-bottom: 0px !important;
}

td:first-of-type {
  min-width: 200px !important;
  padding-left: 10px !important;
  /* min-width: 0px !important; */
}

tfoot {
  background-color: #dcc6b7;
  display: table-footer-group;
  vertical-align: middle;
  border-color: inherit;
  text-align: end;
}

.horizontal {
  height: 30px;
  align-items: center;
  display: grid;
  justify-items: left;
  padding: 10px;
  letter-spacing: 0.1px;
  color: #272424;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
}

.horizontal:nth-child(even) {
  border-left: inherit !important;
}

.header-tabela {
  background-color: #dcc6b7;
}

.paginacao {
  margin-left: calc(100vw - 60%);
}

tr:nth-child(even) {
  background-color: #f4eeea;
}

tr:nth-child(odd) {
  background-color: #ffff;
}

/* Hide the default checkbox */
.custom-checkbox input[type="checkbox"] {
  display: none;
}

/* Style the label as desired */
.custom-checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 7px;
}

/* Style the checkmark using a pseudo-element */
.custom-checkbox .checkmark:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #000;
  background-color: #ffff;
  border-radius: 2px;
  margin-right: 8px;
  vertical-align: middle;
  text-align: center;
  color: #000;
  font-weight: bold;
  line-height: 16px;
}

/* Style the checkmark when the checkbox is checked */
.custom-checkbox input[type="checkbox"]:checked + .checkmark:before {
  content: "✓";
  background-color: #fff;
}

.texto-laranja {
  color: orange;
}

.texto-vermelho {
  color: rgb(197, 37, 24);
}

.texto-preto {
  color: #000;
}

.texto-verde {
  color: #148a6a;
}

.primeira {
  height: 30px;
  align-items: center;
  display: grid;
  justify-items: center;
  padding: 10px;
  letter-spacing: 0.1px;
  color: #272424;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
}

.white-space-no {
  white-space: nowrap;
}

.valor-estoque {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  justify-content: flex-start;
}

.purple {
  background: #8179e2;
  content: "Pendente aprovação";
}
.orange {
  background: #f5a027;
  content: "Não encontrado";
}

.badged-marketplace {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 8px;
  text-align: left !important;
  color: #fff;
}

@media screen and (max-width: 959px) {
  .checkbox-mobile {
    margin-left: 10px;
  }

  .tabela-responsiva {
    height: 70vh;
  }

  .referencia-numero {
    font-size: 10px;
  }

  th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #dcc6b7;
    padding: 0px;
    font-size: 14px;
    padding: 0px 5px;
    /* padding: 0px 10px; */
  }

  td {
    padding: 3px 3px;
  }

  th:nth-child(1) {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background-color: #dcc6b7;
    z-index: 2;
  }

  .coluna-referencia {
    display: flex;
  }

  td:nth-child(1) {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background-color: inherit;
    z-index: 1;
  }

  .referencia-coluna {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10000;
  }

  .custom-checkbox .checkmark:before {
    width: 12px;
    height: 12px;
    line-height: 12px;
  }

  .valor-estoque {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .borda-primeira {
    height: 18px;
    font-size: 10px;
    padding: 5px 4px;
    min-width: 150px;
  }

  .borda-horizontal {
    height: 18px;
    font-size: 10px;
    padding: 1px 4px;
    min-width: 150px;
  }
}
