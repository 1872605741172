.root-card-pequeno {
  background: #fff;
  padding: 15px;
  height: 5vw;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px !important;
  margin-left: 0px;
  line-height: 1.3vw;
  font-family: Inter, sans-serif !important;
  display: flex;
  flex-direction: row;
}

.text-top-pequeno {
  display: flex;
  flex-direction: row;
  padding-top: 5%;
  padding-bottom: 15%;
  align-items: center;
}

.pecas-numero-pac {
  font-weight: 700;
  font-size: 1.6vw;
}
.chip-pacote {
  font-size: 1vw;
  border-radius: 4px;
  width: inherit;
  text-align: center;
  padding: 2px;
  margin-left: 10px;
}
.chip-pacote-red {
  background-color: #e49f98;
  color: #a03127;
  display: flex;
  align-items: center;
}

.chip-pacote-green {
  background-color: #9bd9c9;
  color: #148a6a;
}

.valor-pecas-pac {
  font-weight: 500;
  font-size: 1vw;
  color: #757575;
}

@media screen and (max-width: 959px) {

.root-card-pequeno{
  height: 20vw;
  padding-top: 4vw;
  padding-bottom: 4vw;
  padding-left: 4vw;
}
.pecas-numero-pac {
  font-size: 5vw;
  line-height: 4vw;
}

.valor-pecas-pac {
  font-size: 3.5vw;
  line-height: 4vw;
}

.text-top-pequeno{
  padding-bottom:10%;
}

.chip-pacote {
  font-size: 3vw;
  padding: 2vw;
}
}
