.boxTablePosition{
    width: 100%;
}

.principalBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 13px 0px;
}

.contentBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background: red; */
}

.titlePositionNumber{
    color: var(--color-typography-t-2-dark, #424242);
    font-family: Inter;
    font-size: 18.298px;
    font-style: normal;
    font-weight: 700;
    line-height: 21.957px; /* 120% */
    margin-right: 17px;
}

.titleTablePosition{
    color: var(--color-typography-t-2-medium, #616161);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.957px; /* 137.234% */
}

.percentage{
    color: var(--color-typography-t-2-pure, #272424);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 21.957px; /* 109.787% */
}

.tablePositionIcon{
    fill: var(--color-feedback-success-pure, #148A6A);
    margin-right: 10px;
}

/* Estilos para dispositivos móveis */
@media screen and (max-width: 767px) {
    .boxTablePosition{
        width: 90%;
        margin: 0 auto;
    }   
}