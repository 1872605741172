.button:focus {
  background-color: none
}



.button {
  display: flex;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  color: #616161;
  padding: 0px 24px;
  width: 100%;
  min-width: 200px;
  height: 50px;
  background: #EBEBEB;
  border-radius: 8px;
  border-color: #EBEBEB;
  box-shadow: none;
  border-width: 0px;
  cursor: pointer;
  margin-top: 32px;
}

.button-active {
  color:#272424;
  background: #DCC6B7;
}

.buttonError:hover {
  color:#ffffff;
  background: #DCC6B7;
}

.hover {
  color:#fff;
  background: #DCC6B7;
}

.button:focus {
  background: #DCC6B7;
}

.button__text {
  transition: all 0.2s;
}



.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  font-size: 3px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #000000;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
