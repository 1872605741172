.imageFirst{
    display: flex;
    width: 120px;
    height: 120px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 59px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.image{
    display: flex;
    width: 80px;
    height: 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 80px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.circleFirst{
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    gap: 24px;

    border-radius: 26px;
    background: linear-gradient(270deg, #F9A514 0%, #F1C613 66.15%);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);

    margin-top: -15px;
}

.circleSecond{
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    gap: 24px;

    border-radius: 26px;
    background: linear-gradient(90deg, #B5D4D7 39.46%, #85AAB0 99.88%);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);

    margin-top: -15px;
}

.circleThird{
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    gap: 24px;

    border-radius: 26px;
    background: linear-gradient(270deg, #DF7F32 -0.12%, #E49B58 54.04%);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);

    margin-top: -15px;
}

.principalCircle{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.title{
    color: var(--color-typography-t-2-medium, #616161);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.957px;
    margin-bottom: 8px;

    /* margin-top: 22px; */
}

.percentage{
    color: var(--color-typography-t-2-pure, #272424);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 21.957px; /* 109.787% */
}