.root-card-1 {
  background: #fff;
  padding-left: 1vw;
  padding-top: 1vw;
  padding-right: 1vw;
  padding-bottom: 1vw;
  min-height: 150px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px !important;
  margin-left: 0px;
  position: relative;
}

.card-generico {
  font-family: Inter !important;
  font-weight: 500;
  font-size: 12px;
  width: 72px;
  height: auto;
  border-radius: 8px !important;
  color: #ffffff !important;
  text-align: center;
  padding: 5px;
  vertical-align: baseline;
}

.tamanho-2 {
  min-height: 100px !important;
}
.catalogo {
  background-color: #e3be25 !important;
}

.operacao {
  background-color: #7666ca !important;
}

.grid-icon {
  display: flex;
  flex-direction: row;
}

.icone-frente {
  position: absolute;
  color: #d9d9d9;
  cursor: pointer;
  padding-left: 30px !important;
}

.title2 {
  color: #272424;
  font-size: 16px !important;
  font-family: "Inter", sans-serif !important;
  font-weight: 550 !important;
  line-height: 1.2vw !important;
  text-transform: lowercase;
}

.title2::first-letter {
  text-transform: uppercase;
}

.infoFormat {
  color: #616161;
}

.info-card {
  padding-left: -50vw;
}

.modal-card {
  margin-left: -4vh;
  margin-top: 1.8vh;
}

.title-card {
  font-weight: 500;
  font-size: 13px;
  /* line-height: 21px; */
  color: #616161 !important;
  font-family: "Inter", sans-serif !important;
}

.value-card {
  font-weight: 700 !important;
  font-size: 16px;
  line-height: 21px;
  color: #272424;
  font-family: "Inter", sans-serif !important;
  margin-top: 5px;
}

.grid-icone-frente {
  padding-left: 60%;
  padding-top: 2%;
}

.section-2-card-generic {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.section-2-card-generic-market {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.divider-3 {
  width: 3.333333% !important;
}

@media screen and (max-width: 959px) {
  .root-card-1 {
    min-height: fit-content;
    padding-left: 2vw;
    padding-bottom: 4vw;
    padding-right: 2vw;
    padding-top: 2vw;
  }

  .title2 {
    font-size: 3.5vw !important;
    font-weight: 550 !important;
    color: #272424 !important;
    line-height: 4vw !important;
  }

  .title2-section {
    display: flex;
    align-items: center;
  }

  .title-card {
    margin-top: 15px;
    font-size: 4vw !important;
    padding-bottom: 1vw;
  }

  .value-card {
    font-weight: 700 !important;
    font-size: 4vw;
    line-height: 3vw !important;
  }

  /*   .MuiGrid-grid-md-4 {
    flex-grow: 0;
    max-width: 100% !important;
    flex-basis: 100% !important;
  } */

  .infoFormat {
    margin-right: 1vw;
  }

  .MuiGrid-grid-md-6 {
    flex-grow: 0;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .card-generico {
    height: 18px;
  }
}

@media (min-width: 959px) {
  .footer-card {
    position: absolute;
    bottom: 20px;
    width: 90%;
  }
  .MuiGrid-grid-md-5 {
    flex-grow: 0;
    max-width: 35%;
    flex-basis: 35%;
  }
}

@media screen and (min-width: 959px) {
  .infoFormat {
    color: #616161;
  }
}

@media screen and (min-width: 700px) and (max-width: 959px) {
  .infoFormat {
    color: #616161;
  }
}

@media screen and (min-width: 959px) and (max-width: 2000px) {
  /* .MuiGrid-grid-md-4 {
    flex-grow: 0;
    flex-basis: 50% !important;
  } */

  .div-sku {
    margin-left: -2vw;
  }
}
