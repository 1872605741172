.container-not {
  padding: 0px !important;
  padding-top: 6vh !important;
}

.circleOrange {
  margin: 0 10px 0 0;
  background-color: #e3be25;
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.h6formatnot {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #272424;
  font-family: "Inter", sans-serif !important;
}

.valor-ofertadonot {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #616161 !important;
  font-family: "Inter", sans-serif !important;
  margin: 0 5px;
}

.infoFormat2 {
  color: #616161;
}

.circlePurple {
  margin: 0 10px 0 0;
  background-color: #8179e2;
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.box-atributos {
  margin-top: 10%;
  padding-left: 10%;
}

.titulo-grafico2 {
  color: #272424;
  font-family: "Inter", sans-serif !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  margin-bottom: 1vh !important;
}

.tab-box-total {
  width: 100%;
  height: 100%;
}
/* Style the tab */
.tab {
  max-width: 100vw;
  overflow: auto;
  background-color: #ffffff;
  display: flex;
  flex-wrap: nowrap;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  font-size: 16px;
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  padding-top: 13px;
  padding-left: 11px;
  padding-bottom: 7px;
  border-bottom: 1px solid #cecece;
  min-width: 150px;
  text-decoration: none;
  color: inherit;
  margin-bottom: 1vh !important;
}

.icone-superior {
  /* position: absolute;
    top: 4vw;
    z-index: 1200; */

  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1vw;
}

.title-principal {
  padding-left: 3vw;
}

/* Create an active/current tablink class */
.tab button.active {
  font-weight: 600;
  border-bottom: 3px solid #272424;
  background-color: inherit;
  margin-bottom: 1vh;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 10px;
}

.tab-box {
  background-color: #ffffff;
  height: auto;
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-around;
}

.box-tab-pai {
  width: 100% !important;
  border-radius: 8px;
  background-color: #fff;
  padding: 2px;
  min-height: 250px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.graph-sku {
  display: flex;
  padding-top: 3vw;
}

.div-sku {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.skeleton-nao-ofertados {
  min-height: inherit !important;
  padding: 0px 10px;
}

@media screen and (max-width: 1200px) {
  .div-sku {
    margin-left: 0px;
  }
}
@media screen and (max-width: 959px) {
  .tabcontent {
    padding-top: 24px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .css-yfo96e {
    max-width: 100vw !important;
  }

  .h6formatnot {
    font-size: 4vw !important;
  }

  .valor-ofertadonot {
    font-size: 4vw !important;
  }

  .div-sku {
    display: flex;
    flex-direction: column;
    padding: 3vw 3vw;
  }

  .graph-sku {
    display: flex;
    margin: 0vw;
    padding-top: 0vw;
  }
}

@media screen and (min-width: 1200px) {
  .graph-sku {
    display: flex;
    padding-top: 0px;
  }
}
