.box-foto {
  width: 140px;
  height: auto
}
.img-foto {
  width: 100%;
}
.box-info-foto {
  display: flex;
  align-items: center;
  position: relative !important;
  margin-right: 10px !important;
  margin-bottom: -35px !important;
  padding-top: 8px;
  float: right;
}
.nome-foto {
  margin-top: 10px;
  font-size: 12px;
}
.chip-card-foto {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; 
  height: 15px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  padding: 3px;
  border-radius: 4px;
  margin-right: 30px;
}
.chip-card-foto.sucesso {
  background-color: #23a484;
}
.chip-card-foto.erro {
  background-color: #f94f44;
}
.close-icon {
  width: 32px !important;
  height: 32px !important;
}
