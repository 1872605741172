body {
  background-color: #e5e5e5 !important;
}

.div-exportar {
  display: flex;
  /* justify-content: space-between; */
  padding: 0 10px 0 0px;
  width: 100%;
}

.btn-exportar {
  background-color: inherit !important;
  font-size: 1.4vw !important;
  font-weight: 400 !important;
  text-transform: none !important;
  border: none;
  background-color: #e5e5e5;
  height: fit-content;
}

.total-card-grafico {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1vw;
  line-height: 0.8vw;
  display: flex;
  align-items: center;
  font-feature-settings: "salt" on;
  color: #272424;
}

.cards-grafico {
  display: flex;
  flex-direction: row;
}

.circleBlue {
  background-color: #1196eb;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  margin: 0 10px 0 0;
}

.white-space-estudio {
  padding: 10px 0px;
  width: 100%;
  height: auto;
}

.circleGray {
  background-color: #8b8b8b;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  margin: 0 10px 0 0;
}
.maincard {
  display: flex;
  flex-direction: row;
  padding: 32px;
  background-color: #ebebeb !important;
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
  height: 100vh;
  width: 100%;
  text-align: left important!;
}

.cardtotalstorage {
  flex-direction: column;
  text-align: left !important;
  background: #ffffff;
  padding: 1rem 1.5rem;
  border-radius: 8px;
  max-height: 468px;
  margin-left: 32px;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-right: 50px;
  width: 80%;
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: left !important;
}

.line-1 {
  height: 1px;
  background: #cecece;
}

.circlered {
  background-color: #c52518;
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.circlegreen {
  background-color: #148a6a;
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.circle-estudio {
  margin: 0px 10px 0 0;
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.circle-red {
  background-color: #c52518;
}

.circle-pink {
  background-color: #ffa49c;
}

.circle-green {
  background-color: #148a6a;
}

.h6formatoffer {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #272424;
  font-family: "Inter", sans-serif !important;
}

.valor-ofertado {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #616161 !important;
  font-family: "Inter", sans-serif !important;
}

.infoformatoffer {
  margin-left: -4.5vh;
  margin-top: -2.3vh;
}

.option {
  background-color: #fff;
  padding: 10px;
}

.container {
  padding: 0px 24px !important;
}

.titulo-principal {
  color: #272424;
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  /* font-size: 2.2vw; */
  font-size: 24px;
  line-height: 3vw;
  font-feature-settings: "salt" on;
}

.sub-titulo-principal {
  color: #616161;
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 121.5%;
}

.titulo-grafico2-estudio {
  color: #272424;
  font-family: "Inter", sans-serif !important;
  font-weight: 500 !important;
  font-size: 1.3vw !important;
  line-height: 2vw;
  /*  padding-right: 2%;
  margin-top: -2%; */
}

.titulo-grafico-estudio {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.box-atributos2 {
  margin-top: 10% !important;
  padding-left: 10%;
}

.card-numero-pecas {
  display: flex;
  flex-direction: row;
  gap: 2%;
  margin-left: 25px;
  align-items: center;
}

.chip-estudio-red {
  background-color: #e49f98;
  color: #a03127;
  padding: 3px 3px !important;
}

.chip-estudio-yellow {
  background-color: #e3cc6c;
  padding: 3px 3px !important;
  margin-left: 10px;
}

.chip-estudio-green {
  background-color: #9bd9c9;
  color: #148a6a;
  padding: 3px 5px !important;
}

.pecas-numero {
  font-weight: 700;
  font-size: 1.6vw;
  /* padding-bottom: 5%; */
}

.texto-foto-ag {
  font-weight: 400;
  font-size: 1.2vw;
  color: #8b8b8b;
}

.texto-foto {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.texto-receita {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 5%;
  margin-left: 25px !important;
}

.scrollmenu {
  background-color: #333;
  display: flex;
  flex-direction: row;
  overflow: auto !important;
  white-space: nowrap;
}

.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

.scrollmenu a:hover {
  background-color: #777;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.graph-estudio {
  display: flex;
  padding-top: 5px;
  /* padding-top: 3vw; */
}

.div-sku {
  display: flex;
  flex-direction: column;
}

.MuiTypography-body1 {
  font-size: 1rem;
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  line-height: 5.5vw;
  letter-spacing: 0.00938em;
}

.divider-principal {
  border-top: #cecece solid 1px;
  width: 100%;
  margin-bottom: 1vw;
}

/* .graph-pecas{
  margin-left: -1vw;
} */
.box-individual-estudio {
  /* flex-wrap: nowrap !important; */
  border-radius: 8px;
  padding: 10px 15px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  background-color: #fff;
}
.tamanho-box-estudio {
  height: 50vh;
}

@media screen and (max-width: 959px) {
  .box-individual-estudio {
    box-shadow: none;
    height: 60vh;
  }
  .maincard {
    flex-direction: column !important;
  }
  .maincard {
    margin-left: 0 !important;
    margin-bottom: 32px !important;
  }

  .maincard:last-child {
    margin-bottom: 0 !important;
  }

  .maincard section.card button {
    font-size: 70% !important;
  }
  .titulo-principal {
    font-size: 5vw;
    line-height: 5vw;
  }

  .titulo-grafico {
    font-size: 5.5vw !important;
    font-weight: 500 !important;
    padding-bottom: 3vh;
  }

  .sub-titulo-1 {
    font-size: 4vw !important;
  }

  .sub-titulo-2 {
    font-size: 4vw !important;
  }

  .box-individual {
    padding: 0px 0px !important;
  }

  .box-atributos2 {
    padding-left: 0px !important;
  }

  .h6formatoffer {
    font-weight: 600;
    font-size: 4vw;
    line-height: 19px;
    color: #272424;
    padding-bottom: 0px;
    font-family: "Inter", sans-serif !important;
  }

  .valor-ofertado {
    font-weight: 500 !important;
    font-size: 4vw !important;
    line-height: 19px !important;
    color: #616161 !important;
    font-family: "Inter", sans-serif !important;
  }

  .div-sku {
    display: flex;
    flex-direction: column;
    padding: 3vw 3vw;
  }

  .graph-sku {
    display: flex;
    margin: 0vw;
    padding-top: 0vw;
  }

  .cards-grafico {
    display: flex;
    flex-direction: column;
  }

  .box-principal {
    margin: 1vw 0vw;
    width: 100%;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    padding: 0px 0px;
  }

  .pecas-numero {
    font-size: 4vw;
  }

  .btn-exportar {
    font-size: 3vw;
  }

  .btn-exportar-text {
    display: none;
  }

  .titulo-grafico2-estudio {
    font-weight: 500 !important;
    font-size: 4vw !important;
    line-height: 2rem;
    /*  padding-right: 2%;
    margin-top: -2%; */
  }

  .total-card-grafico {
    line-height: 5vw;
    font-size: 3.5vw;
  }

  .texto-foto-ag {
    display: none;
  }

  .texto-foto-mob-ag {
    font-size: 3.5vw;
    line-height: 5vw;
    color: #8b8b8b;
    font-weight: 400;
  }

  .texto-foto {
    font-size: 3.5vw;
    line-height: 5vw;
  }

  .texto-receita {
    font-size: 3vw;
    line-height: 5vw;
  }

  .div-exportar {
    padding-top: 1.5vw;
    padding-bottom: 1.5vw;
    display: flex;
    align-items: center;
  }

  .circle-estudio {
    margin: 0px 10px 0 0;
    height: 3vw;
    width: 3vw;
    border-radius: 50%;
  }
}

@media screen and (min-width: 959) {
  .texto-foto-mob-ag {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .graph-sku {
    display: flex;
    padding-top: 0px;
  }
}

@media screen and (min-width: 1800px) and (max-width: 2150px) {
  .box-individual-estudio {
    height: 50vh;
  }
}

@media screen and (min-width: 2150px) {
  .box-individual-estudio {
    min-height: 60vh;
  }
}
