.modalFormat-picker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 24px;
}



.css-173nr8z-MuiFormLabel-root {
  color: #000000 !important;
  font-size: 1.2rem !important;
  font-weight:300 !important;
  padding-bottom: 10px !important;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-weight: 400 !important;
  font-size: 0.9rem !important;
}

.css-dlxvmz-MuiButtonBase-root-MuiButton-root {
color: #272424 !important;
box-shadow: none !important;
width: auto !important;
background-color: initial !important;
}

.adicionar {
  display: flex;
  justify-content: center;
}

.adicionar-botao {
  background-color: #dbc7b8 !important;
}


  
 