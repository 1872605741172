.card-total {
  margin: 0 30px;
  /* margin-left: 6vw;
  margin-right: 4.5vw; */
}

.box-card-total {
  border-radius: 8px;
  background-color: white;
  padding: 2%;
  margin-top: 2%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.card-pequeno {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1vw;
  margin-top: 24px;
  margin-bottom: 24px;
}

.divider-pacote {
  margin: 10px 0;
  /* margin-top: 10px;
  margin-bottom: 10px; */
  /* margin-left: -37px; */
  border-bottom: #cecece solid 0.5px;
  /* width: 88vw; */
}

.btn-exportar-csv-estudio {
  margin-top: 24px !important;
  display: flex;
  justify-content: flex-end !important;
}

.tab-estudio {
  max-width: 100vw;
  overflow: auto;
  display: flex;
  flex-wrap: nowrap;
}

/* Style the buttons inside the tab */
.tab-estudio button {
  transition: 0.3s;
  font-size: 16px;
  padding-left: 11px;
  color: inherit;
  text-transform: none !important ;
}

/* Create an active/current tablink class */
.tab-estudio button.active {
  border-bottom: 3px solid #272424;
  background-color: inherit;
  margin-bottom: 1vh;
}

.data-picker-email {
  display: flex;
  gap: 5%;
  padding-left: 2%;
  align-items: center;
}

.css-1tw6gjc-MuiTabs-indicator {
  background-color: black !important;
}

.css-1gsv261 {
  border-bottom: 0px solid !important;
  border-color: rgba(0, 0, 0, 0.12);
}

.MuiTabPanel-root {
  padding: 0px 0px !important;
}

.Mui-selected {
  color: #000 !important;
  font-weight: 700 !important;
  text-transform: none;
  color: inherit;
}

.icones-cinza {
  cursor: pointer;
  color: #dadada;
}

.tab-mobile {
  padding-bottom: 0vw;
}

.box-tabs {
  display: flex;
}

.data-picker-email {
  display: flex;
}

.flex-tab {
  display: flex;
}

.btn-exportar-mobile {
  display: none;
}

@media screen and (max-width: 959px) {
  .divider-pacote {
    display: none;
  }

  .tab-estudio button {
    font-size: 16px;
    padding-left: 1vw;
  }

  .btn-exportar-mob {
    background-color: #e5e5e5 !important;
    border: 2px solid #e5e5e5 !important;
  }
  .divider-pacote {
    display: none;
  }

  .tab-mobile-formata {
    padding-bottom: 10vw;
  }

  .box-tabs-formata {
    display: flex;
    flex-direction: column;
  }

  .data-picker-email-formata {
    display: flex;
    justify-content: flex-end;
  }

  .btn-exportar-mobile {
    display: flex;
    background-color: #e5e5e5 !important;
    align-items: center;
    /* border: 2px solid #e5e5e5 !important; */
    /* box-shadow: none !important; */
  }

  .icones-esconder {
    display: none;
  }

  .icones-black {
    color: #000 !important;
  }

  .flex-tab-mobile {
    display: flex;
    flex-direction: column;
  }

  .btn-exportar-csv-estudio {
    display: none;
  }

  .card-total {
    margin: 0 12px;
  }

  .card-pequeno {
    margin: 0px 4px;
    width: 100%;
  }
}
