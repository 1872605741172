.card-fotos {
  background: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px 24px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px !important;
  margin-left: 0px;
  height: 160px;
}
.card-foto-sub-titulo {
  font-weight: 600;
  font-size: 18px;
  font-family: "Inter", sans-serif !important;
  line-height: 36px;
  letter-spacing: 0em;
  margin-right: 10px;
}
.car-fotos-box-titulo {
  display: flex;
  align-items: center;
}
.card-fotos-info {
  display: flex;
  margin: 10px 0px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #8b8b8b;
}

.label-onClick {
  height: calc(100% - 20%);
  display: block;
  cursor: pointer;
}
