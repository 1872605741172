.titulo-principal-user-info {
    color: #272424;
    font-family: "Inter", sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    text-transform: capitalize;
  }


  .sub-titulo-principal-user-info {
    color: #616161;
    font-family: "Inter", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 121.5%;
  }