.selected {
  background-color: #DBC7B8;
  color: #fff;
  border-radius: 4px;
}

.selected:hover {
  background-color: #DBC7B8;
}

.calendar {
  font-family: Arial, sans-serif;
  width: 350px;
  height: 390px;
  border-radius: 4px;
  padding: 20px;
}

.botao-seta {
  border: none;
  background-color: transparent;  
}

.modalFormat-picker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 6px;
}

.fechar {
cursor: pointer;
display: flex;
justify-content: flex-end;
padding-bottom: 10px;
}

.espacamento {
  padding-left: 1vw;
  padding-right: 1.15vw;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.days-of-week {
  display: flex;
  justify-content: space-between;
}

.weekdays{
  display: flex;
  gap: 6%;
  padding-left: 4%;
  padding-bottom: 5%;
}

.day-of-week {
  width: calc(100% / 7);
  text-align: center;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}

.days {
  display: flex;
  flex-wrap: wrap;
}



.blank {
  width: calc(100% / 7);
  height: 20px;
}

.day {
  width: calc(100% / 7);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.day:hover {
  background-color: #eee;
  border-radius: 4px;
}

.css-1nq4hrt-MuiButtonBase-root-MuiButton-root {
background-color: #DBC7B8 !important;
color: #fff !important;
}

.OK{
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
