.root-card-estudio {
  background: #fff;
  padding-left: 1vw;
  padding-top: 1vw;
  padding-right: 1vw;
  padding-bottom: 1vw;
  height: 13vw;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px !important;
  margin-left: 0px;
  line-height: 1.8vw;
  font-family: Inter, sans-serif !important;
}

.circle-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.grid-icon-estudio {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title-card-estudio {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5vw;
  width: 95%;
}

.title-text {
  font-family: Inter;
  font-weight: 700;
  font-size: 1.2vw;
  color: #1a1a1a;
}

.data-pacote {
  font-size: 1vw;
  /* margin-left: 5px; */
}

.calendar-format {
  color: #272424;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
}

.icone-estudio {
  display: flex;
}

.card-divider-estudio {
  display: flex;
}

.container-divider-estudio {
  display: flex;
  flex-direction: column;
}

.pecas {
  font-weight: 500;
  font-size: 1vw;
  color: #757575;
}

.valor-pecas {
  font-weight: 500;
  font-size: 1vw;
  color: #757575;
}

.pecas-no {
  font-weight: 400;
  font-size: 0.8vw;
  color: #d66157;
}

.valor-pecas-no {
  font-weight: 500;
  font-size: 1vw;
  color: #d66157;
}

.divider-estudio {
  border-top: #05291f solid 1px;
  width: 18vw;
  margin-top: 1vw;
  margin-bottom: 1vw;
}

.text-end {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.chip-estudio {
  font-size: 1vw;
  border-radius: 4px;
  width: inherit;
  height: auto;
  text-align: center;
  padding: 0 3px;
}

.chip-estudio-tamanho {
  height: 30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2.2523px 5.63075px;
  font-weight: 400;
  font-size: 0.8vw;
  color: #d66157;
}
.chip-prioridade {
  background-color: #E3CC6C;
  margin-left: 10px;
  height: 20px;
  text-align: center;
  padding: 0 5px;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

@media screen and (max-width: 959px) {
  .chip-estudio {
    font-size: 3vw;
  }

  .title-text {
    font-size: 4vw;
    line-height: 5vw;
  }

  .data-pacote {
    font-size: 4vw;
    line-height: 5vw;
  }

  .pecas {
    font-size: 4vw;
    line-height: 6vw;
  }

  .valor-pecas {
    font-size: 4vw;
    line-height: 5vw;
  }

  .pecas-no {
    font-size: 4vw;
    line-height: 5vw;
    padding-right: 1vw;
  }

  .valor-pecas-no {
    font-size: 4vw;
    line-height: 5vw;
  }

  .root-card-estudio {
    height: 40vw;
    padding-left: 2vw;
    padding-top: 2vw;
    padding-right: 2vw;
    padding-bottom: 2vw;
  }

  .divider-estudio {
    width: 85vw;
    margin-top: 5vw;
    margin-bottom: 5vw;
  }
}
