.botao-exportar {
  border: none;
  background-color: #e5e5e5;
  cursor: pointer;
  font-size: 1.4vw !important;
  font-weight: 400 !important;
  text-transform: none !important;
  height: fit-content;
  color: #272424;
  display: flex;
  align-items: center;
  gap: 5%;
}

@media screen and (max-width: 959px) {
  .botao-title {
    display: none;
  }
}
