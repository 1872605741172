.dropdown {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 55px
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
}

.dropdown-content a:hover {
  background-color: #F4EEEA;
}

.titulo-drop-mover-fotos {
  padding: 16px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.iten-drop-mover-fotos {
  padding: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  display: flex;
  align-items: center;
}

.iten-drop-mover-fotos :hover {
  background-color: #f4eeea;
}
