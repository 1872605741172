.p-topo {
  padding: 32px 0;
}
.p-16-card-foto {
  padding: 16px;
  margin-left: 8px;
  height: 240px;
  max-width: 140px;
}

.card-fotos-generico {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
}
.card-fotos-generico-2 {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 12px 8px;
}
.card-fotos-generico-3 {
  width: 100%;
  /* margin-right: 34px; */
}
.card-fotos-generico-ativo {
  background-color: #dcc6b7;
  padding-left: 10px !important;
  /* margin-left: -10px !important; */
}

.card-fotos-generico-titulo {
  font-family: "Inter", sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  font-family: Inter, sans-serif !important;
}

.card-fotos-generico-divider {
  border-bottom: #ebebeb solid 1px;
  width: 100%;
}

.card-fotos-generico-barra-acao {
  align-items: center;
  display: flex;
  margin: 15px 0;
  /* padding: 12px 8px; */
  /* width: 100%; */
}
.card-fotos-generico-barra-acao-icones {
  margin-left: 40px;
  display: flex;
  align-items: flex-end;
  color: #e5e5e5;
  gap: 24px;
}
.img-sem-fotos {
  width: 200px;
  height: 160px;
  margin: 0 auto;
}
.container-sem-fotos {
  display: grid;
}
.box-linha-fotos-ref {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 300px;
  margin-left: -23px;
}
.f-14 {
  font-size: 14px !important;
}
.box-fotos {
  margin: 0px !important;
  margin-top: 24px !important;
  min-height: 20vh;
}

.box-info-check-todas-refs {
  margin: 40px 16px 16px 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.tab-upload-fotos {
  border-bottom: 1px solid #cecece;
  width: 100%;
  overflow: auto;
  background-color: #ffffff;
  display: flex;
  flex-wrap: initial;
}

.button-tab-upload-fotos {
  cursor: pointer;
  font-size: 16px;
  padding: 5px 12px;
  margin-bottom: -1px !important;
  border: inherit;
  background-color: #ffffff;
  margin-right: 32px;
}

.active {
  font-weight: 600;
  border-bottom: 3px solid #272424;
  background-color: inherit;
  margin-bottom: 1vh;
}
.card-back-blue {
  background-color: #e7f5ff !important;
}

@media screen and (max-width: 959px) {
  .card-fotos-generico-3 {
    margin-right: initial !important;
  }
}
