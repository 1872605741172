.card-diretorio {
  width: 100%;
  padding: 16px 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.cor-normal {
  background-color: #f5efea;
}
.cor-selecionada {
  background-color: #d3edff;
}

.alingamento-card-diretorio {
  display: flex;
  align-items: center;
}
.p-card-diretorio {
  margin: 0 10px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #151715;
  word-break: break-all;
}
.menu-card-diretorio {
  float: right;
}

.p-l-0:first-child {
  padding-left: 0px !important;
}
.box-breadcrumbs {
  margin-bottom: 10px;
}
