.box-foto-upload {
  padding: 16px;
  border: 0.4px solid #eeeeee;
  width: 140px;
  word-wrap: break-word;
}

.img-foto-upload {
  width: 140px;
  height: auto;
  margin-bottom: 8px;
}

.close-icones-fotos {
  text-align: right;
  width: 135px;
  margin-bottom: -40px;
  z-index: 9999999;
  position: relative;
}
.nome-foto-upload {
  text-align: center;
  margin-top: 8px;
  font-size: 10px;
}
